export function compareByOrder<T>(a: T, b: T, order: T[], orderForUnknown: 1 | -1 = 1): number {
  const indexA = order.indexOf(a)
  const indexB = order.indexOf(b)

  if (indexA === -1 || indexB === -1) {
    return indexA === -1 ? indexB === -1 ? 0 : orderForUnknown : -orderForUnknown
  }

  return indexA - indexB
}

export const compareBooleans = (a: unknown, b: unknown): number => {
  const booleanA = Boolean(a)
  const booleanB = Boolean(b)

  return booleanA === booleanB ? 0 : (booleanA && !booleanB) ? 1 : -1
}

export const compareNumbers = (a: unknown, b: unknown, orderForNaN: 1 | -1 = -1): number => {
  const aNumber = Number(a)
  const bNumber = Number(b)
  const isANaN = isNaN(aNumber)
  const isBNaN = isNaN(bNumber)

  if (isANaN || isBNaN) {
    return isANaN ? isBNaN ? 0 : orderForNaN : -orderForNaN
  }

  return aNumber - bNumber
}
