export type ReadFileAsTypeMap = {
  arrayBuffer: ArrayBuffer
  binaryString: string
  dataUrl: string
  text: string
}

export const readFileAs = async <T extends keyof ReadFileAsTypeMap>(file: File, as: T): Promise<ReadFileAsTypeMap[T]> => {
  const reader = new FileReader()

  return new Promise((resolve, reject) => {
    reader.addEventListener('load', () => {
      resolve(reader.result! as ReadFileAsTypeMap[T])
    })

    reader.addEventListener('error', reject)

    switch (as) {
      case 'arrayBuffer':
        reader.readAsArrayBuffer(file)
        break

      case 'binaryString':
        reader.readAsBinaryString(file)
        break

      case 'dataUrl':
        reader.readAsDataURL(file)
        break

      case 'text':
        reader.readAsText(file)
        break
    }
  })
}
