// https://github.com/Aaronius/jquery-scrollparent
export function getScrollParent(element: HTMLElement, includeHidden = false): Element | null {
  let style = window.getComputedStyle(element)
  const excludeStaticParent = style.position === 'absolute'
  const overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/

  if (style.position === 'fixed') {
    return document.scrollingElement
  }

  let parent: HTMLElement | null = element

  // eslint-disable-next-line no-cond-assign
  for (; (parent = parent.parentElement);) {
    style = window.getComputedStyle(parent)

    if (excludeStaticParent && style.position === 'static') {
      continue
    }

    if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
      return parent
    }
  }

  return document.scrollingElement
}
