const getSelector = (name: string) => `[data-loader="${name}"]`

export const getLoader = (name: string) => {
  const selector = getSelector(name)
  const loader = document.querySelector<HTMLElement>(selector)

  if (!loader) {
    console.warn(`Loader element "${selector}" not found`)
  }

  return loader
}

export const showLoader = (name: string) => {
  const loader = getLoader(name)

  if (loader) {
    loader.style.display = ''
  }
}

export const hideLoader = (name: string) => {
  const loader = getLoader(name)

  if (loader) {
    loader.style.display = 'none'
  }
}
