import { objValues } from './object'

// Extracts just the values from a numeric enum
// This is needed because of reverse mappings of numeric enums
// https://www.typescriptlang.org/docs/handbook/enums.html#reverse-mappings
export const enumNumberValues = <TValues extends number>(
  object: Record<string, TValues | string>,
): Array<TValues> => {
  return objValues(object).filter((value): value is TValues => typeof value === 'number')
}
