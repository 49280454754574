import { withDisposableAnchor } from './withDisposableAnchor'

const parseErrorMessage = async (error: string): Promise<string> => {
  try {
    const xml = new window.DOMParser().parseFromString(error, 'text/xml')
    const messageNode = xml.querySelector('Message')

    return messageNode?.innerHTML ?? error
  }
  catch {
    return error
  }
}

export const downloadFile = async (
  url: string,
  filename: string,
): Promise<void> => {
  const response = await fetch(url).catch(error => error as Error)

  if (response instanceof Error) {
    throw response
  }

  if (!response.ok) {
    const message = await parseErrorMessage(await response.text())
    throw new Error(message, { cause: response })
  }

  const blob = await response.blob()
  const href = URL.createObjectURL(blob)

  withDisposableAnchor((a) => {
    a.href = href
    a.download = filename
    document.body.appendChild(a)
    a.click()
  })
}
